/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from 'src/environments/environment';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/user.model';
import { StorageService } from './storage.service';
import { APIResult } from "../models/APIResult.model";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private storageService: StorageService = inject(StorageService);

  private userIdsForTesting = 6;

  // private authService: AuthService = inject(AuthService);

  constructor(private http: HttpClient) {}

  public async getCurrentUser(): Promise<User> {
    console.log('[UserService] getCurrentUser() - invoked');
    return (await this.storageService.getLocalData('user')) as User;
  }

  public async setActiveUserId(userId: number): Promise<void> {
    console.log('[UserService] setActiveUserId() - invoked');
      await this.storageService.setItem('userId', userId);
      console.log('[UserService] setActiveUserId() - userId set in StorageService: ', await this.storageService.getItem('userId'));
    }

  public async getActiveUserId_OLD(): Promise<number> {
    console.log('[UserService] getActiveUserId() - invoked');
    if(await this.storageService.getItem('userId')){
      console.log('[UserService] getActiveUserId() - userId in StorageService: ', await this.storageService.getItem('userId'));
        return (await this.storageService.getItem('userId')) as number;
    }
    console.log('[UserService] getActiveUserId() - userId not found in StorageService');
    return null;
  }

  // TODO: Refactor: De apiService te gebruiken
  public async getAllUsers(): Promise<User[]> {
    try {
      const users = await this.http
        .get<any>(`${environment.apiUrl}/users`)
        .toPromise();
      users.data.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));

      return users.data.filter((user) => user.status === 'ACTIVE');
    } catch (error) {
      console.error('Unable to find users. Error: ', error);
      throw error;
    }
  }

  /**
   * Fetches a single user from the API and returns it as a User object
   *
   * @param userId The id of the user to fetch
   * @returns User|error
   */
  public async getUser(userId: number): Promise<User> {
    try {
      const user = await this.http
        .get<APIResult<User>>(`${environment.apiUrl}/users/${userId}`)
        .toPromise();

      // TODO 24/07/2024 Roy Schrauwen: Create a User object from the fetched data

      return user.data[0];
    } catch (error) {
      // console.log(error);
      console.error(`Unable to find user with id ${userId}`);
      throw error;
    }
  }

  /**
   * Fetches all users from the API convert them into User objects and return an array with those objects
   *
   * @returns User[]
   */
  public fetchUsers(): User[] {
    const fetchedUsers = this.http.get<any>(`${environment.apiUrl}/users`);

    const users: User[] = [];

    fetchedUsers.subscribe((data) => {
      data.data.forEach((user) => {
        users.push(
          User.create(
            user.id,
            user.username,
            user.firstName,
            user.lastName,
            user.email,
            user.rol_id
          )
        );
      });
    });

    return users;
  }

  /**
   * Fetches a single user from the API convert it into a User object and return that object
   *
   * @param id The id of the user to fetch
   * @returns User
   */
  public fetchSingleUser(id: number): User {
    // Get a single user from the API
    const fetchedUser = this.http.get<any>(`${environment.apiUrl}/users/${id}`);

    const user: User = new User();

    // Convert fetchedUser into a User object
    fetchedUser.subscribe((data) => {
      user.setUsername(data.username);
      user.setVoornaam(data.voornaam);
      user.setAchternaam(data.achternaam);
      user.setEmail(data.email);
      user.setRolId(data.rol_id);
      user.activeShow = data.activeShow;
    });

    return user;
  }

  public async getActiveShowIdForUser(): Promise<number> {
    // TODO: Maak dit werkend
    return (this.userIdsForTesting == await this.getActiveUserId()) ? 2 : 1;
  }

  public async getActiveUserToken(): Promise<string> {
    if(await this.storageService.getItem('caro-logboek-token')){
      return (await this.storageService.getItem('caro-logboek-token')) as string;
    }
    console.log('[UserService] getActiveUserToken() - caro-logboek-token not found in StorageService');
    return null;
  }

  public async getActiveUserId(): Promise<number> {
    console.log('[UserService] getUserIdFromJWT() - invoked');
    const token = await this.getActiveUserToken();
    if(token){
      const payload = token.split('.')[1];
      const decodedPayload = atob(payload);
      return JSON.parse(decodedPayload).user?.id ?? null;
    }
    console.log('[UserService] getUserIdFromJWT() - token not found');
    return null;
  }

}
