import { inject, Injectable } from '@angular/core';
import { StorageService } from "../services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class ShowService {

  private storageService: StorageService = inject(StorageService);


  public async setActiveShowId(showId: number): Promise<void> {
    console.log('[ShowService] setActiveShowId() - setting showId in StorageService: ', showId);
    await this.storageService.setItem('showId', showId);
  }

  public async getActiveShowId(): Promise<number | null> {
    if(await this.storageService.getItem('showId')){
      return (await this.storageService.getItem('showId')) as number;
    }
    // TODO 25/07/2024 Roy Schrauwen: Fix this in a later sprint when there are more shows
    return 1;
  }



}
