import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { PushNotifications, PushNotificationSchema, } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserTracing, instrumentAngularRouting } from '@sentry/angular-ivy';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  // public imageViewerOpen = false;
  // public featureFlags: FeatureFlag[] = [];
  public pushNotificationToken: string = '';

  public isAppUser = true;

  public isPushNotificationsAvailable =
    Capacitor.isPluginAvailable('PushNotifications');

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertController: AlertController,
    private toastController: ToastController,
    private userService: UserService,
    // private featureFlagService: FeatureFlagService
  ) {}

  async ngOnInit(): Promise<void> {
    if (environment.appEnvironment === 'test') {
      await this.initSentry();
    }
    if (this.isPushNotificationsAvailable) {
      this.setupPushNotifications();
    }

    // await this.featureFlagService.getAll();

    // this.isAppUser = await this.userService.getActiveUserId() != 6;

    // const featureFlagSubscription =
    //   this.featureFlagService.featureFlags.subscribe((featureFlags) => {
    //     this.featureFlags = featureFlags;
    //   });
  }

  // ngOnDestroy(): void {
  //   this.featureFlagService.featureFlags.unsubscribe();
  // }

  // public isFeatureFlagActive(featureFlagName: string): boolean {
  //   const featureFlag = this.featureFlags.find(
  //     (ff) => ff.name === featureFlagName
  //   );
  //   return featureFlag?.active ?? false;
  // }

  public async initSentry() {
    Sentry.init({
      dsn: 'https://ed5565928717fddb870ae24af88b10d9@o4505273847054336.ingest.sentry.io/4505976057364480',
      release: 'carologboekreader@' + environment.appVersion,
      environment: environment.appEnvironment,
      integrations: [
        new CaptureConsole({
          levels: ['error'],
        }) as any,
        new BrowserTracing({
          tracingOrigins: ['localhost'],
          routingInstrumentation: instrumentAngularRouting,
        }),
      ],
      tracesSampleRate: 1,
    });

    console.log('[AppComponent] initSentry() - Finding active user');
    const activeUserId = await this.userService.getActiveUserId();
    const activeUser = await this.userService.getUser(activeUserId);
    const activeUserUsername = activeUser?.username ?? 'username unknown';
    const userId = activeUserId ?? 99999;

    Sentry.configureScope((scope) => {
      scope.setTag('user_mode', 'testing-user');
      scope.setUser({ id: userId, username: activeUserUsername });
    });
  }

  public async logOut() {
    await this.authService.logout();
    await this.router.navigateByUrl('/auth');
  }

  public getAppVersion(): string {
    return environment.appEnvironment + '-' + environment.appVersion;
  }

  public getAppName(): string {
    return environment.appName ?? 'CARO Logboek';
  }



  public async onLogout() {
    const alert = await this.alertController.create({
      header: 'Uitloggen?',
      message: 'Weet je zeker dat je uit wilt loggen?',
      buttons: [
        {
          text: 'Nee',
        },
        {
          text: 'Ja',
          handler: () => this.logOut(),
        },
      ],
    });

    await alert.present();
  }

  private setupPushNotifications(): void {
    this.checkNotificationPermissions();
    this.initPushNotificationListenerBackground();
    this.initPushNotificationListenerToast();
  }

  private initPushNotificationListenerToast() {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        const toast = await this.toastController.create({
          message: notification.title,
          duration: 5000,
          position: 'bottom',
          layout: 'stacked',
          // buttons: [
          //   {
          //     text: 'Toon',
          //     role: 'info',
          //     handler: () => {
          //       alert('Navigeer naar bericht');
          //     },
          //   },
          // ],
        });

        await toast.present();
      }
    );
  }

  private async checkNotificationPermissions() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM

        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: any) => {
        console.log('My token: ' + JSON.stringify(token));
        this.pushNotificationToken = JSON.stringify(token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });
  }

  private async initPushNotificationListenerBackground() {
    PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      const data = notification.notification.data;
      console.log('APP [AppComponent] pushNotificationActionPerformed() - notification: ', notification);
      console.log('APP [AppComponent] pushNotificationActionPerformed() - data: ', data);

      if (data.type === 'showreport') {
        const showreportId = data.id;
        const url = '/showreport/' + showreportId;
        console.log('APP [AppComponent] () - navigeer naar: ' + url);
        this.router.navigateByUrl(url);
      }

      if (data.type === 'log') {
        const logId = data.id;
        const url = '/logboek/' + logId;
        console.log('APP [AppComponent] () - navigeer naar: ' + url);
        this.router.navigateByUrl(url);
      }

    });

  }
}
