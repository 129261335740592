import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private thisStorage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this.thisStorage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this.thisStorage?.set(key, value);
  }

  saveLocalData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getLocalData(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }


  public async getItem(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const storedValue = localStorage.getItem(key);
        if(storedValue) {
          try {
            const parsedJson = JSON.parse(storedValue);
            resolve(parsedJson);
          }
          catch(error) {
            console.debug('Failed to parse the stored value as JSON.', error, storedValue);
          }
        }
        return resolve(storedValue);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async setItem(key: string, value: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const jsonString = JSON.stringify(value);
        localStorage
          .setItem(key, jsonString);
        resolve();
      } catch (error) {
        reject(error);
      }
    }
    );
  }

  clearLocalData(key: string) {
    localStorage.removeItem(key);
  }
}
