import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SlackBotService } from './slack-bot.service';
import { APIResult } from '../models/APIResult.model';
import { UserService } from './user.service';
import { ShowService } from "../app/show.service";

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  // TODO: Maak hier een class van
  private errorMessagesMap: { [key: number]: string } = {
    0: '0: Kan geen verbinding maken met de server.',
    404: '404: De gevraagde bron kon niet worden gevonden.',
    // Voeg hier meer statuscodes en foutmeldingen toe indien nodig
  };


    private http: HttpClient = inject(HttpClient);
    private slackBotService: SlackBotService = inject(SlackBotService);
    private userService: UserService = inject(UserService);
    private showService: ShowService = inject(ShowService);



  public static buildUrl(endpoint: string): string {
    return `${environment.apiUrl}/${endpoint}`;
  }

  public async getAll<T>(endpoint: string): Promise<T[]> {
    const url = ApiService.buildUrl(endpoint);

    const headers = await this.buildHeaders();
    console.log('[ApiService] getAll() - headers:', headers);

    return (
      await this.handleRequest<APIResult<T>>(
        this.http.get<APIResult<T>>(url, { headers })
      )
    ).data;
  }

  // This is marked as unused, but it should be used, right? Or is this obsolete now every call has the showId in the header?
  // And is the header the correct place for that? The JWT should be there, but maybe all other information should be in the body?
  public async getAllForShow<T>(endpoint: string, showId: number): Promise<T[]> {
    const url = ApiService.buildUrl(endpoint) + '?showId=' + showId;
    const headers = await this.buildHeaders();
    return (
      await this.handleRequest<APIResult<T>>(
        this.http.get<APIResult<T>>(url, { headers })
      )
    ).data;
  }

  public async getAppVersion(): Promise<string> {
    const url = ApiService.buildUrl(`version`);
    const headers = await this.buildHeaders();
    const result = await this.handleRequest<APIResult<string>>(
      this.http.get<APIResult<string>>(url, { headers })
    );
    return result.data[0];
  }

  public async getById<T>(endpoint: string, id: number): Promise<T> {
    const url = ApiService.buildUrl(`${endpoint}/${id}`);
    const headers = await this.buildHeaders();
    const result = await this.handleRequest<APIResult<T>>(
      this.http.get<APIResult<T>>(url, { headers })
    );
    return result.data[0];
  }

  public async logError(error: any): Promise<void> {
    try {
      await this.slackBotService.sendError('APP [APIService]', JSON.stringify(error));
    } catch (e) {
      console.error('APP [ApiService] logError() error: ', e);
    }
  }

  public async post<T>(endpoint: string, data: any): Promise<T[]> {
    const url = ApiService.buildUrl(endpoint);
    const headers = await this.buildHeaders();
    return (
      await this.handleRequest<APIResult<T>>(
        this.http.post<APIResult<T>>(url, data, { headers })
      )
    ).data;
  }

  private async handleRequest<T>(request: Observable<T>): Promise<T> {

    try {
      return await request.toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const status = error.status;
        const errorMessage =
          this.errorMessagesMap[status] ||
          'Er is een onverwachte fout opgetreden.';

        console.error('[ApiService] handleRequest failed:', errorMessage);
        this.logError(error);
      }

      throw new Error('An error occurred while communicating with the API.');
    }
  }

  public async getSpecific<T>(endpoint: string, start: number, amount: number) {

    const currentShowId = await this.showService.getActiveShowId();

    const url =
      ApiService.buildUrl(endpoint) + '?start=' + start + '&amount=' + amount+ '&showId=' + currentShowId;
    const headers = await this.buildHeaders();
    return (
      await this.handleRequest<APIResult<T>>(
        this.http.get<APIResult<T>>(url, { headers })
      )
    ).data;
  }

  private async buildHeaders() {
    try {
      const userToken = await this.userService.getActiveUserToken();
      const currentShowId = await this.showService.getActiveShowId();
      return { Authorization: `Bearer ${ userToken }`, Show: `${ currentShowId }`};
    } catch (e) {
      console.error('APP [ApiService] buildHeaders() error: ', e);
      return {};
    }
  }
}
