import { Component } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Picture } from '../../../models/picture.interface.cs';

@Component({
  selector: 'app-fullscreen-image-modal',
  templateUrl: 'fullscreen-image-modal.component.html',
})
export class FullscreenImageModalComponent {
  public picture: Picture;

  constructor(private modalCtrl: ModalController) {}

  public async closeModal() {
    await this.modalCtrl.dismiss();
  }
}
