/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { UserService } from "../../services/user.service";
import { UserCredentials } from "./auth.page";
import { APIResult } from "../../models/APIResult.model";
import { ApiService } from "../../services/api.service";
import { User } from "../../models/user.model";
import { ShowService } from "../show.service";

const TOKEN_KEY = 'caro-logboek-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';
  _user = new BehaviorSubject<any>(null);


  public currentUser: User | null = null;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private userService: UserService,
    private showService: ShowService
  ) {
    this.checkVersion();
    this.loadToken().then(() => {
    });
  }

  public get userId() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          console.log('APP [AuthService] () - user.id:', user.id);
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  public async loadToken() {
    console.log('[AuthService] loadToken() - invoked');
    const token = await this.userService.getActiveUserToken();
    if (token) {
      this.token = token;

      const storedUserId = await this.userService.getActiveUserId();
      if (!storedUserId) {
        console.error('[AuthService] loadToken() - No storedUserId found, but user is in the app. Logging out.');
        this.isAuthenticated.next(false);
        await this.logout();
        return;
      }

      // const user = await this.userService.getUser(storedUserId);
      try {
        const user = await this.userService.getUser(storedUserId);
        this.currentUser = user;
        await this.showService.setActiveShowId(user.activeShow?.id ?? 1);
        console.log('[AuthService] loadToken() - currentUser:', this.currentUser);
        const httpStatus = user.status;
      } catch (e) {
        console.error('APP [AuthService] loadToken() - error:', e);
        if (e.status === HttpStatusCode.Forbidden || e.status === HttpStatusCode.Unauthorized) {
          console.error('APP [AuthService] loadToken() - Unauthorised User');
        }
        this.isAuthenticated.next(false);
        await this.logout();
        return;
      }

      // if (storedUserId === 32) {
      //   await this.logout();
      //   this.isAuthenticated.next(false);
      //   return;
      // }

      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }


  public async loginUser(credentials: UserCredentials): Promise<string | null> {

    const url = ApiService.buildUrl(`auth/login`);
    const headers = await this.buildHeaders();


    try {
      const result = (
        await this.handleRequest<APIResult<string>>(
          this.http.post<APIResult<string>>(url, credentials, { headers })
        )
      ).data;
      console.log('[AuthService] loginUser() - result: ', result);
      this.token = result.toString();
      // this.storageService.saveLocalData(TOKEN_KEY, this.token);
      await this.storageService.setItem(TOKEN_KEY, this.token);
      this.isAuthenticated.next(true);
      return result.toString();
    } catch (e) {
      console.error('[AuthService] loginUser() - error: ', e);
    }

    return null;


  }




  public async login(credentials: { username; password }): Promise<Observable<any>> {




    return this.http.post('https://royschrauwen.nl/apitest/login', credentials).pipe(
      tap((data: LoginResponse) => {
        if (data.idUser) {
          console.log('[AuthService] saving user id - ', Number(data.idUser));
          this.storageService.saveLocalData('userId', Number(data.idUser));
        } else {
          console.warn('APP [AuthService] () - data.idUser is undefined or null. This should not happen!');
        }
        if (data.token) {
          this.token = data.token;
        }
      }),
      switchMap(data => from(this.userService.getUser(data.idUser)).pipe(
        catchError(error => {
          console.warn('Error fetching user with id ', data.idUser, error);
          this.isAuthenticated.next(false);
          throw error; // Rethrow or handle it as needed
        })
      )),
      tap(user => {
        // Do something with user data if needed
        console.log('[AuthService] login() - ');
        this.storageService.saveLocalData(TOKEN_KEY, this.token);
        this.isAuthenticated.next(true);
      }),
      catchError(error => {
        // Handle any error that occurred in previous steps
        console.error('Error during login process', error);
        throw error; // Rethrow or handle it as needed
        // return of({ error }); // Return an observable with error to handle it in subscription
      })
    );
  }

  private async handleRequest<T>(request: Observable<T>): Promise<T> {
    // try {
      return await request.toPromise();
    // } catch (error) {
    //   if (error instanceof HttpErrorResponse) {
    //     const status = error.status;
    //   }
    //   throw new Error('An error occurred while communicating with the API.');
    // }
  }


  // public login(credentials: { username; password }): Observable<any> {
  //   console.log('[AuthService] login() invoked');
  //
  //   return this.http
  //     .post('https://royschrauwen.nl/apitest/login', credentials)
  //     .pipe(
  //       tap((data: any) => {
  //         console.log('[AuthService] data received: ', data);
  //         console.log('APP [AuthService] () - data.userId:' + data.idUser);
  //
  //         if (data.idUser) {
  //           this.storageService.saveLocalData('userId', Number(data.idUser));
  //         } else {
  //           console.warn('APP [AuthService] () - data.idUser is undefined or null. This should not happen!');
  //         }
  //       }),
  //       switchMap((data: any) => {
  //         if (data.idUser) {
  //           return from(this.userService.getUser(data.idUser)).pipe(
  //             catchError((error) => {
  //               console.warn('Error fetching user with id ', data.idUser, error);
  //               this.isAuthenticated.next(false);
  //               return of(null);
  //             })
  //           );
  //         }
  //         return of(data);
  //       }),
  //       map((data: any) => data.token),
  //       switchMap((token) => (this.token = token)),
  //       tap((_) => {
  //         console.log('[AuthService] token received: ', this.token);
  //         this.storageService.saveLocalData(TOKEN_KEY, this.token);
  //         this.isAuthenticated.next(true);
  //       })
  //     );
  // }

  // login(credentials: { username; password }): Observable<any> {
  //   console.log('[AuthService] login() invoked');
  //   // TODO: Replace the URL when the new API is available
  //   return this.http
  //     .post('https://royschrauwen.nl/apitest/login', credentials)
  //     .pipe(
  //       tap((data: any) => {
  //         console.log('[AuthService] data received: ', data);
  //         console.log('APP [AuthService] () - data.userId:' + data.idUser);
  //         if(data.idUser) {
  //           this.storageService.saveLocalData('userId', Number(data.idUser));
  //         } else {
  //           console.warn('APP [AuthService] () - data.idUser is undefined or null. This should not happen!');
  //         }
  //       }),
  //       map((data: any) => data.token),
  //       switchMap((token) => (this.token = token)),
  //       tap((_) => {
  //         console.log('[AuthService] token received: ', this.token);
  //         this.storageService.saveLocalData(TOKEN_KEY, this.token);
  //
  //         this.isAuthenticated.next(true);
  //       })
  //     );
  // }

  public logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.storageService.clearLocalData(TOKEN_KEY);
    return;
  }

  public getUserIsAuthenticated() {
    return this.isAuthenticated;
  }

  private checkVersion(): void {
  }


  private async buildHeaders() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const token = await this.userService.getActiveUserToken();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = { Authorization: `Bearer ${token}` };

    return headers;

  }

}


interface LoginResponse {
  idUser?: number; // Maak het optioneel met '?', als het kan ontbreken
  token?: string; // Voorbeeld, voeg andere verwachte eigenschappen toe zoals nodig
}
